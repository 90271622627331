"use client";

import { ShopApi } from "@/api/shop";
import { config } from "@/config/config";
import { useNavigation } from "@/context/navigation";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import Navigation from "./navigation";

type Props = {};

const HeaderSeven: React.FC<Props> = (props) => {
  const [search, setSearch] = useState(true);
  const [productList, setProductList] = useState<ProductShopListDto[]>([]);
  const { mobile, openMenuMobile } = useNavigation();

  useEffect(() => {
    const fetch = async () => {
      try {
        await ShopApi.GetProductShopList({
          limit: 4,
          fixed: true,
        }).then((ret) => {
          setProductList(ret.result);
        });
      } catch (ex) {}
    };
    fetch();
  }, []);

  return (
    <div className="rbt-header-wrapper header-not-transparent header-sticky">
      <div className="container">
        <div className="mainbar-row rbt-navigation-end align-items-center">
          <div className="header-left rbt-header-content d-none d-xl-block">
            <div className="header-info">
              <div className="logo">
                <Link href="/">
                  <Image src="/novos/img/logo-horizontal-colorida.png" width={98} height={50} alt="Clinique Cursos" loading="eager" />
                </Link>
              </div>
            </div>
          </div>
          <div className="rbt-main-navigation d-none d-xl-block">
            <Navigation />
          </div>
          <div className="header-right gap-5">
            <ul className="quick-access">
              <li className="access-icon">
                <Link className={`search-trigger-active rbt-round-btn ${search ? "" : "open"}`} href="#" onClick={() => setSearch(!search)}>
                  <i className="feather-search"></i>
                </Link>
              </li>
            </ul>

            <div className="rbt-btn-wrapper d-none d-xl-block">
              <Link className="rbt-btn btn-small btn-secondary radius-round" href={config.dash_login}>
                Entrar
              </Link>
            </div>

            <div className="mobile-menu-bar d-block d-xl-none">
              <div className="hamberger">
                <button type="button" className="hamberger-button rbt-round-btn" onClick={() => openMenuMobile(!mobile)}>
                  <i className="feather-menu"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`rbt-search-dropdown ${!search ? "active" : ""}`}>
          <div className="wrapper">
            <div className="row">
              <div className="col-lg-12">
                <form action={config.dash_shop} method="get">
                  <input type="text" placeholder="Busque por nossos cursos" id="busca" name="busca" />
                  <div className="submit-btn">
                    <button type="submit" className="rbt-btn btn-small btn-secondary radius-round">
                      Buscar
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="rbt-separator-mid">
              <hr className="rbt-separator m-0" />
            </div>

            <div className="row g-4 pt--30 pb--60">
              <div className="col-lg-12">
                <div className="section-title">
                  <h5 className="rbt-title-style-2">Destaques</h5>
                </div>
              </div>
              {productList.slice(0, 4).map((item, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                  <div className="rbt-card variation-01 rbt-hover">
                    <div className="rbt-card-img">
                      <Link href={`${config.dash_shop}detalhe/${item.id}`} target="_blank">
                        <Image src={item.thumbnail} width={186} height={128} alt="Card image" />
                      </Link>
                    </div>
                    <div className="rbt-card-body">
                      <h5 className="rbt-card-title">
                        <Link href={`${config.dash_shop}detalhe/${item.id}`} target="_blank">
                          {item.name}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSeven;
