export const config = {
  url: process.env.URL as string,
  dash_shop: process.env.URL_DASH_SHOP as string,
  dash_login: process.env.URL_DASH_LOGIN as string,
  dash_cadastro: process.env.URL_DASH_CADASTRO as string,
  termo_uso: process.env.URL_DASH_TERMO_USO as string,
  politica_privacidade: process.env.URL_DASH_POLITICA_PRIVACIDADE as string,
  apple_app: "...",
  google_app: "...",
  emptyImage: "https://aule-files.sfo2.cdn.digitaloceanspaces.com/system-defaults/lesson-empty.png",
  contentChannelHome: process.env.CONTENT_CHANNEL_HOME as string,
  contentChannelBlog: process.env.CONTENT_CHANNEL_BLOG as string,
  gtm: "GTM-PQDH6XVT",
  instagramToken:
    "IGQWRPbkMwMjQ5VEZAJX2lDNWRWMjd0dGlFZATVIY3pieHJraHJrSDN0akhpZA3QzSUlkeVdvTE9ydHA5cnQwRC1kUEM3dzMxZAGplRkNQSVBUTzZA4T0RkWTQzQUFiWmNrVTFfRmhtRTZAPUUJoWlZA4OURrSGhpeVpfM3cZD",
  social: {
    whatsapp: "https://wa.me/553431950950?text=Olá Nique, pode me ajudar",
    facebook: "https://web.facebook.com/cliniquecursos",
    instagram: "https://www.instagram.com/cliniquecursos",
    twiter: undefined,
    youtube: undefined,
    tiktok: undefined,
    linkdin: undefined,
  },
  contactInfo: [
    {
      title: "Whatsapp",
      contact: "+55 (34) 3217-5616",
      link: "https://wa.me/553431950950?text=Olá Nique, pode me ajudar",
      icon: "feather-message-circle",
    },
    {
      title: "E-mail",
      contact: "ajuda@cliniquecursos.com.br",
      link: "mailto:ajuda@cliniquecursos.com.br",
      icon: "feather-mail",
    },
  ],
  api: {
    domaindId: "eWwrHagXGkC5EZSqNRvUkA==",
    token: "eWwrHagXGkC5EZSqNRvUkA==",
    endPoint: {
      content: process.env.API_CONTENT as string,
      course: process.env.API_COURSE as string,
      shop: process.env.API_SHOP as string,
      web: process.env.API_WEB as string,
    },
  },
  enableModules: {
    showNews: false,
    showTestimony: false,
  },
};
