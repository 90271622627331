import { LayoutKeys, Layouts } from "@/layout/layouts";
import "bootstrap/scss/bootstrap.scss";
import { NextComponentType, NextPage, NextPageContext } from "next";
import { AppProps } from "next/app";
import "../../public/scss/blog/_blog.scss";
import "../../public/scss/default/euclid-circulara.scss";

// ========= Plugins CSS START =========
import "sal.js/dist/sal.css";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../public/css/plugins/animation.css";
import "../../public/css/plugins/euclid-circulara.css";
import "../../public/css/plugins/feather.css";
import "../../public/css/plugins/fontawesome.min.css";
import "../../public/css/plugins/odometer.css";
// ========= Plugins CSS END =========

import MobileMenu from "@/components/header/MobileMenu";
import { config } from "@/config/config";
import NavigationProvider from "@/context/navigation";
import Head from "next/head";
import Script from "next/script";
import { useEffect } from "react";
import "../../public/scss/styles.scss";

export type MyPage<P = {}, IP = P> = NextPage<P, IP> & {
  Layout?: LayoutKeys;
};

export type MyAppProps = AppProps & {
  Component: NextComponentType<NextPageContext, any, any> & {
    Layout: LayoutKeys;
  };
};

export default function App({ Component, pageProps }: AppProps) {
  let l: LayoutKeys = "Main";
  if (Component && Component.displayName) {
    l = Component.displayName as LayoutKeys;
  }
  const Layout = Layouts[l] ?? ((page: string) => page);
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  return (
    <NavigationProvider>
      <Layout>
        <Head>
          <title>Clinique - Excelência na formação em saúde</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <Script id="gtm" strategy="afterInteractive">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.gtm}');
          `}
        </Script>
        <MobileMenu />
        <Component {...pageProps} />
      </Layout>
    </NavigationProvider>
  );
}

